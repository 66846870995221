.c-data-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  font-size: 12px;
  position: relative;

  & > label {
    color: $text3;
    display: flex;
    align-items: center;
  }
  &-group {
    padding: 0 10px;
    .c-checkBox-label {
      color: $text3;
    }
  }
  &-inner {
    color: $text2;
    display: flex;
    align-items: center;

    // overwrite
    .web-c-skeleton-content .web-c-skeleton-row {
      width: 40px !important;
      height: 18px;
    }
  }
  &-popper {
    display: none;
    color: $text2;
    position: absolute;
    right: 0;
    border-radius: 6px;
    background: $dark1;
    align-items: center;
    padding: 10px;
    top: 28px;
    line-height: 20px;
    z-index: 4;

    &:before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      background: $dark1;
      position: absolute;
      top: -3px;
      right: 10px;
      @include xz(45);
    }
  }
  &-arrow {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 5px;
    background: center/contain no-repeat;
  }

  &-line {
    border: none;
    height: 1px;
    background: $line;
  }

  &-increase &-arrow {
    background-image: url($res + 'icon/arrow-right-plus.svg');
  }
  &-increase &-inner,
  &-increase &-popper {
    span {
      color: $green;
    }
  }

  &-decrease &-arrow {
    background-image: url($res + 'icon/arrow-right-minus.svg');
  }

  &-decrease &-inner,
  &-decrease &-popper {
    span {
      color: $red;
    }
  }

  &:hover &-popper {
    display: flex;
  }
}

.mobile-mode {
  .c-data-item {
    height: rem(28);
    font-size: rem(12);

    &-group {
      padding: 0 rem(10);
    }
    &-inner {
      // overwrite
      .web-c-skeleton-content .web-c-skeleton-row {
        width: rem(40) !important;
        height: rem(18);
      }
    }
    &-popper {
      border-radius: rem(6);
      padding: rem(10);
      top: rem(28);
      line-height: rem(20);

      &:before {
        width: rem(7);
        height: rem(7);
        right: rem(10);
      }
    }
    &-arrow {
      width: rem(16);
      height: rem(16);
      margin: 0 rem(5);
    }
  }
}
