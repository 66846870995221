.web-leverage-select {
  position: relative;
  &-curr {
    height: 48px;
    border: 2px solid $ce7;
    border-radius: 24px;
    padding: 0 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    justify-content: space-between;
    background: $cff;
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      display: block;
      margin-left: 8px;
      background: url($res + 'icon/arrow-down.svg') center/contain no-repeat;
    }
  }
  &-stepper {
    position: absolute;
    right: 0;
    top: 62px;
    display: none;
    align-items: center;
    background: $cff;
    box-shadow: 0 0 8px rgba($c00, 0.2);
    border-radius: 24px;
    padding: 32px;

    .web-button {
      margin-left: 12px;
    }
  }
  &.show {
    z-index: 2;
  }
  &.show &-curr {
    border-color: $red;
    color: $red;
    &:after {
      background-image: url($res + 'icon/arrow-up.svg');
    }
  }
  &.show &-stepper {
    display: flex;
  }
}

.mobile-mode {
  .web-leverage-select {
    &-curr {
      height: rem(36);
      border-width: rem(2);
      border-radius: rem(18);
      padding: 0 rem(10);
      font-size: rem(14);
      &:after {
        width: rem(12);
        height: rem(12);
        margin-left: rem(8);
      }
    }
    &-stepper {
      top: rem(42);
      border-radius: rem(18);
      padding: rem(24);

      .web-button {
        margin-left: rem(8);
      }
    }
  }
}

.dark,
.mobile-mode.dark {
  .web-leverage-select {
    &-curr {
      border-color: $c44;
      background: $c22;
      &:after {
        background-image: url($res + 'icon/arrow-down-dark.svg');
      }
    }
    &-stepper {
      background: $c22;
      box-shadow: 0 0 8px rgba($c00, 0.8);
    }
    &.show .web-leverage-select-curr {
      border-color: $orange;
      color: $orange;
      &:after {
        background-image: url($res + 'icon/arrow-up-dark.svg');
      }
    }
  }
}
