$statistic-margin-bottom-title: 8px;
$statistic-margin-left-suffix: 5px;
$statistic-margin-right-prefix: 5px;
$statistic-class-prefix: 'web-c-statistic';

.#{$statistic-class-prefix} {
  font-size: 20px; // debug

  &-title {
    margin-bottom: $statistic-margin-bottom-title;
  }

  &-content {
    .web-c-skeleton-content .web-c-skeleton-row {
      height: 28px;
    }
  }

  &-value {
    display: flex;
    align-items: baseline;
    &-suffix {
      font-size: 12px;
      margin-left: $statistic-margin-left-suffix;
    }

    &-prefix {
      color: green; // debug
      margin-right: $statistic-margin-right-prefix;
    }
  }
}
