.token-search-modal {
  &[data-reach-dialog-overlay] {
    justify-content: flex-end !important;
    align-items: flex-start !important;
    padding-top: 48px;
  }
  & > [data-reach-dialog-content] {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
  }
}
