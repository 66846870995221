.web-select {
  display: inline-flex;
  align-items: center;
  label {
    display: block;
    font-size: 18px;
    margin-right: 8px;
  }
  &-show {
    display: flex;
    position: relative;

    button {
      min-width: 170px;
      height: 48px;
      border: 2px solid $ce7;
      border-radius: 24px;
      padding: 0 22px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      justify-content: space-between;
      background: none;
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        margin-left: 8px;
        background: url($res + 'icon/arrow-down.svg') center/contain no-repeat;
      }
    }
  }
  &-options {
    position: absolute;
    left: 0;
    top: 56px;
    padding: 12px;
    background: $cff;
    border-radius: 24px;
    box-shadow: 0px 0px 8px rgba($c00, 0.2);
    display: none;
    min-width: 170px;
    z-index: 10;
    ul {
      max-height: 480px;
      overflow-y: auto;
      padding: 12px;
      li {
        height: 48px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        white-space: nowrap;
        cursor: pointer;
        @include dz();
        &:hover {
          background: $cf7;
          color: $red;
        }
        &.active,
        &.active:hover {
          background: $red;
          color: $cff;
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
  &.show &-show {
    z-index: 2;
  }
  &.show &-show button {
    border-color: $red;
    color: $red;
    &:after {
      background-image: url($res + 'icon/arrow-up.svg');
    }
  }
  &.show &-options {
    display: block;
  }
}

.mobile-mode {
  .web-select {
    flex-flow: column;
    align-items: flex-start;
    label {
      font-size: rem(14);
      margin-bottom: rem(4);
      margin-right: 0;
      padding-left: rem(18);
    }
    &-show {
      button {
        min-width: rem(100);
        height: rem(36);
        border-radius: rem(18);
        padding: 0 rem(18);
        font-size: rem(14);
        &:after {
          width: rem(10);
          height: rem(10);
          margin-left: rem(8);
        }
      }
    }
    &-options {
      top: rem(48);
      padding: rem(10);
      border-radius: rem(18);
      min-width: rem(100);
      ul {
        max-height: rem(280);
        padding: rem(10);
        li {
          height: rem(36);
          border-radius: rem(18);
          padding: 0 rem(12);
          &:not(:last-child) {
            margin-bottom: rem(4);
          }
        }
      }
    }
  }
}
.dark,
.mobile-mode.dark {
  .web-select {
    &-show {
      button {
        border-color: $c44;
        &:after {
          background-image: url($res + 'icon/arrow-down-dark.svg');
        }
      }
    }
    &-options {
      background: $c22;
      box-shadow: 0px 0px 8px rgba($c00, 0.8);
      ul {
        li {
          &:hover {
            background: $c33;
            color: $orange;
          }
          &.active,
          &.active:hover {
            background: $orange;
            color: $c22;
          }
        }
      }
    }
    &.show .web-select-show button {
      border-color: $orange;
      color: $orange;
      &:after {
        background-image: url($res + 'icon/arrow-up-dark.svg');
      }
    }
  }
}
