.c-row-show {
  display: flex;
  align-items: center;

  &.pre-arrow:before,
  &.sub-arrow:after {
    content: '';
    display: block;
    background: center/contain no-repeat;
  }
  &.pre-arrow:before {
    margin-right: 5px;
  }

  &.sub-arrow:after {
    margin-left: 5px;
  }

  &.rise {
    color: $rise;
    &:before,
    &:after {
      background-image: url($res + 'icon/arrow-rise.svg');
    }
  }
  &.fall {
    color: $fall;
    &:before,
    &:after {
      background-image: url($res + 'icon/arrow-fall.svg');
    }
  }

  &-size-default {
    font-size: 16px;
    &.pre-arrow:before,
    &.sub-arrow:after {
      width: 16px;
      height: 16px;
    }
  }

  &-size-small {
    font-size: 14px;
    &.pre-arrow:before,
    &.sub-arrow:after {
      width: 14px;
      height: 14px;
    }
  }
  &-size-mini {
    font-size: 12px;
    &.pre-arrow:before,
    &.sub-arrow:after {
      width: 12px;
      height: 12px;
    }
  }
}

.mobile-mode {
  .c-row-show {
    &.pre-arrow:before {
      margin-right: rem(5);
    }

    &.sub-arrow:after {
      margin-left: rem(5);
    }

    &-size-default {
      font-size: rem(16);
      &.pre-arrow:before,
      &.sub-arrow:after {
        width: rem(16);
        height: rem(16);
      }
    }

    &-size-small {
      font-size: rem(14);
      &.pre-arrow:before,
      &.sub-arrow:after {
        width: rem(14);
        height: rem(14);
      }
    }
    &-size-mini {
      font-size: rem(12);
      &.pre-arrow:before,
      &.sub-arrow:after {
        width: rem(12);
        height: rem(12);
      }
    }
  }
}
