.c-stepper {
  display: flex;
  align-items: center;
  background: $dark1;
  height: 40px;
  justify-content: space-between;
  border-radius: 6px;
  padding: 0 12px;
  button {
    position: relative;
    cursor: pointer;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $text3;
    @include dz();
    &:before,
    &:after {
      content: '';
      width: 12px;
      height: 2px;
      border-radius: 1px;
      background: $dark1;
      position: absolute;
      left: 50%;
      top: 50%;
      @include dz();
      margin-left: -6px;
      margin-top: -1px;
    }
    &:hover {
      background: lighten($text3, 10%);
    }

    &:disabled,
    &:disabled:hover {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &-add {
    &:after {
      @include xz(90);
    }
  }

  label,
  input {
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    flex: 1;
    border: none;
    background: none;
    color: $text2;
  }

  &.simple {
    height: auto;
    padding: 0;
    display: inline-flex;
    background: inherit;
    label {
      padding: 0 12px;
      color: $cff;
      font-weight: 400;
    }
    button {
      border-radius: 6px;
    }
  }
}
.mobile-mode {
  .c-stepper {
    height: rem(40);
    border-radius: rem(6);
    padding: 0 rem(12);
    button {
      width: rem(24);
      height: rem(24);
      @include dz();
      &:before,
      &:after {
        width: rem(12);
        height: rem(2);
        border-radius: rem(1);
        margin-left: rem(-6);
        margin-top: rem(-1);
      }
    }

    label,
    input {
      padding: 0 rem(10);
      font-size: rem(16);
    }

    &.simple {
      label {
        padding: 0 rem(12);
      }
      button {
        border-radius: rem(6);
      }
    }
  }
}
