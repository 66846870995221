.c-button {
  border: none;
  cursor: pointer;
  @include dz();
  font-weight: bold;
  position: relative;

  &,
  span,
  em {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &-type {
    &-default {
      background: $blue;
      color: $text1;
      &:hover,
      &:focus {
        background: lighten($blue, 5%);
      }
      &.disabled {
        background: lighten($blue, 12%);
        color: rgba($text1, 0.5);
      }
    }
    &-long {
      background: $green;
      color: $text1;
      &:hover,
      &:focus {
        background: lighten($green, 5%);
      }
      &.disabled {
        background: lighten($green, 12%);
        color: rgba($text1, 0.5);
      }
    }
    &-short {
      background: $red;
      color: $text1;
      &:hover,
      &:focus {
        background: lighten($red, 5%);
      }
      &.disabled {
        background: lighten($red, 12%);
        color: rgba($text1, 0.5);
      }
    }

    &-dark,
    &-line {
      background: $dark1;
      color: $text1;
      &:hover,
      &:focus {
        background: lighten($dark1, 5%);
      }
      &.disabled {
        background: $dark1;
        color: $text3;
      }
    }
    &-line {
      box-shadow: 0 0 0 2px $bg inset;
    }
  }

  &-size {
    &-super {
      height: 76px;
      padding: 0 56px;
      font-size: 24px;
      border-radius: 16px;

      &.round {
        border-radius: 38px;
      }
      &.icon {
        width: 76px;
        padding: 0;
      }

      img {
        display: block;
        max-height: 24px;
        & + * {
          margin-left: 8px;
        }
      }
    }
    &-large {
      height: 50px;
      padding: 0 44px;
      font-size: 18px;
      border-radius: 10px;

      &.round {
        border-radius: 25px;
      }
      &.icon {
        width: 50px;
        padding: 0;
      }

      img {
        display: block;
        max-height: 24px;
        & + * {
          margin-left: 8px;
        }
      }
    }
    &-default {
      height: 40px;
      padding: 0 20px;
      font-size: 15px;
      border-radius: 8px;
      &.round {
        border-radius: 20px;
      }
      &.icon {
        width: 40px;
        padding: 0;
      }

      img {
        display: block;
        max-height: 24px;
        & + * {
          margin-left: 8px;
        }
      }
    }

    &-small {
      height: 32px;
      padding: 0 16px;
      font-size: 13px;
      border-radius: 6px;
      &.round {
        border-radius: 16px;
      }
      &.icon {
        width: 32px;
        padding: 0;
      }

      img {
        display: block;
        max-height: 24px;
        & + * {
          margin-left: 8px;
        }
      }
    }
    &-mini {
      height: 24px;
      padding: 0 6px;
      font-size: 12px;
      border-radius: 4px;
      font-weight: normal;
      &.round {
        border-radius: 12px;
      }
      &.icon {
        width: 24px;
        padding: 0;
      }

      img {
        display: block;
        max-height: 16px;
        & + * {
          margin-left: 4px;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.loading {
    span {
      height: 0;
      overflow: hidden;
    }
    .c-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &.full {
    display: flex;
    width: 100%;
  }
}

.mobile-mode {
  .c-button {
    &-size {
      &-super {
        height: rem(76);
        padding: 0 rem(56);
        font-size: rem(24);
        border-radius: rem(16);

        &.round {
          border-radius: rem(38);
        }
        &.icon {
          width: rem(76);
          padding: 0;
        }

        img {
          display: block;
          max-height: rem(24);
          & + * {
            margin-left: rem(8);
          }
        }
      }
      &-large {
        height: rem(50);
        padding: 0 rem(44);
        font-size: rem(18);
        border-radius: rem(10);

        &.round {
          border-radius: rem(25);
        }
        &.icon {
          width: rem(50);
          padding: 0;
        }

        img {
          display: block;
          max-height: rem(24);
          & + * {
            margin-left: rem(8);
          }
        }
      }
      &-default {
        height: rem(40);
        padding: 0 rem(20);
        font-size: rem(15);
        border-radius: rem(8);
        &.round {
          border-radius: rem(20);
        }
        &.icon {
          width: rem(40);
          padding: 0;
        }

        img {
          display: block;
          max-height: rem(24);
          & + * {
            margin-left: rem(8);
          }
        }
      }

      &-small {
        height: rem(32);
        padding: 0 rem(16);
        font-size: rem(13);
        border-radius: rem(6);
        &.round {
          border-radius: rem(16);
        }
        &.icon {
          width: rem(32);
          padding: 0;
        }

        img {
          display: block;
          max-height: rem(24);
          & + * {
            margin-left: rem(8);
          }
        }
      }
      &-mini {
        height: rem(24);
        padding: 0 rem(6);
        font-size: rem(12);
        border-radius: rem(4);
        font-weight: normal;
        &.round {
          border-radius: rem(12);
        }
        &.icon {
          width: rem(24);
          padding: 0;
        }

        img {
          display: block;
          max-height: rem(16);
          & + * {
            margin-left: rem(4);
          }
        }
      }
    }
  }
}
