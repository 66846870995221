.web-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  &-inner {
    width: 100px;
    height: 100px;
    position: relative;
    @include fd(0.5);
  }
  &-type {
    &-section {
      min-height: 160px;
    }
    &-fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 30;
      background: rgba($cff, 0.5);
    }
    &-float {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 30;
      background: rgba($cff, 0.5);
      @include mh(4px);
    }
  }
  span {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $blue;
    position: absolute;
    opacity: 0.2;
    animation: load 1.04s ease infinite;
    &:nth-child(1) {
      left: 0;
      top: 50%;
      margin-top: -8px;
      animation-delay: 0.13s;
    }
    &:nth-child(2) {
      left: 14px;
      top: 14px;
      animation-delay: 0.26s;
    }
    &:nth-child(3) {
      left: 50%;
      top: 0;
      margin-left: -8px;
      animation-delay: 0.39s;
    }
    &:nth-child(4) {
      top: 14px;
      right: 14px;
      animation-delay: 0.52s;
    }
    &:nth-child(5) {
      right: 0;
      top: 50%;
      margin-top: -8px;
      animation-delay: 0.65s;
    }
    &:nth-child(6) {
      right: 14px;
      bottom: 14px;
      animation-delay: 0.78s;
    }
    &:nth-child(7) {
      bottom: 0;
      left: 50%;
      margin-left: -8px;
      animation-delay: 0.91s;
    }
    &:nth-child(8) {
      bottom: 14px;
      left: 14px;
      animation-delay: 1.04s;
    }
  }
}
