.web-c-token-unit {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
  em {
    margin-right: 5px;
  }
}
