@use "sass:math";
.web-c-chart-loading {
  width: 100%;
  height: 100%;
  background: $bgBlue;
  filter: contrast(8);
  // @include jb($bgBlue, $white, 90);
  &-warp {
    width: 100%;
    height: 100%;
  }
  &-footer {
    position: absolute;
    bottom: -10px;
    left: 0;
    top: 90%;
    width: 100%;
    background: #5983b3;

    &:before {
      content: '';
      position: absolute;
      top: -300px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      backdrop-filter: blur(5px);
    }

    span {
      position: absolute;
      border-radius: 50%;
      background-color: #5983b3;

      @for $i from 0 through 100 {
        &:nth-child(#{$i}) {
          $width: 30 + random(100) + px;
          left: #{(random(100)) + '%'};
          top: #{(30 + random(100))}px;
          width: $width;
          height: $width;
          animation: moveToTop
            #{math.div(random(2500) + 1500, 1000)}s
            ease-in-out -#{math.div(random(5000), 1000)}s
            infinite;
        }
      }
    }
  }
}

@keyframes moveToTop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0.08;
    transform: translate(-50%, -180px) scale(0.3);
  }
}
