.c-slider {
  height: 16px;
  width: 100%;
  cursor: pointer;
  &-layout {
    position: relative;
    height: 50px;
    width: 100%;
    @include ns();
  }
  &-track {
    height: 16px;
    display: flex;
    align-items: center;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
    }
    &-0,
    &-1 {
      position: absolute;
      left: 0;
    }
    &-0 {
      &:after {
        height: 4px;
        border-radius: 2px;
        background: $blue;
      }
    }
    &-1 {
      right: 0;
      &:after {
        height: 3px;
        background: $dark1;
        border-radius: 1px;
      }
    }
  }
  &-thumb,
  &-thumb-mark {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    background: $text1;
    // border: 2px solid $blue;
    box-shadow: 0 0 0 3px $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      background: url($res + 'icon/slider-heart.svg') center/contain no-repeat;
    }
  }
  &-thumb-mark {
    position: absolute;
    z-index: 2;
    right: 0;
    @include yd(50%, 0);
  }
  &-marks {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 16px;
    align-items: center;
    cursor: pointer;
    &.pointer {
      pointer-events: none;
    }
    span {
      display: block;
      width: 6px;
      height: 14px;
      border-radius: 3px;
      background: $text3;
      position: relative;
      cursor: pointer;
      border: 1.5px solid $dark1;
      &.active {
        background: $text1;
        border-color: $blue;
      }

      em {
        display: block;
        position: absolute;
        color: $text3;
        font-size: 12px;
        line-height: 24px;
        top: 24px;
        left: 50%;
        @include yd(-50%, 0);
      }

      &:first-child em {
        left: 0;
        @include yd(0, 0);
      }
      &:last-child em {
        right: 0;
        @include yd(0, 0);
        left: auto;
      }

      &.curr em {
        color: $text2;
        font-weight: bold;
      }
    }
  }
}

.mobile-mode {
  .c-slider {
    height: rem(16);
    &-layout {
      height: rem(50);
    }
    &-track {
      height: rem(16);
      &-0 {
        &:after {
          height: rem(4);
          border-radius: rem(2);
        }
      }
      &-1 {
        &:after {
          height: rem(3);
          border-radius: rem(1);
        }
      }
    }
    &-thumb,
    &-thumb-mark {
      width: rem(16);
      height: rem(16);
      box-shadow: 0 0 0 rem(3) $blue;
      &:before {
        width: rem(15);
        height: rem(15);
      }
    }

    &-marks {
      height: rem(16);
      span {
        width: rem(6);
        height: rem(14);
        border-radius: rem(3);
        border: rem(1.5) solid $dark1;

        em {
          font-size: rem(12);
          line-height: rem(24);
          top: rem(24);
        }
      }
    }
  }
}
