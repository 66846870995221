$bg: #1c1c28;
$dark1: #303044;
$dark2: #232334;
$dark3: #171722;
$dark4: #12121a;
$line: #2d2d3d;

$text1: #f7f7f7;
$text2: #c3c2d4;
$text3: #6f6e84;
$text4: #eeac48;

$blue: #2a85ff;
$green: #36c78a;
$red: #f46778;
$purple: #8e59ff;
$yellow: #eeac48;
$cyan: #88fff8;

$l-blue: #b1e5fc;
$l-green: #b5e4ca;
$l-orange: #ffbc99;
$l-purple: #cabdff;
$l-yellow: #ffd88d;

.rise {
  color: $green;
}
.fall {
  color: $red;
}
.warn {
  color: $yellow;
}
