.web-form-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  & > label {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 18px;
    width: 140px;
    img {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  &-inner {
    flex: 1;
    position: relative;
  }
  &-tip {
    display: flex;
    justify-content: center;
    width: 472px;
    margin-left: 36px;
    flex-flow: column;
    font-size: 12px;
    line-height: 20px;
    height: 48px;
    em {
      color: $red;
    }
  }
  &-error {
    color: $red;
    font-size: 12px;
    display: block;
    position: absolute;
    left: 24px;
    bottom: -16px;
    z-index: 1;
  }
  &.error {
    // background: $red;
    input[type='text'],
    input[type='password'],
    input[type='tel'],
    textarea,
    .web-form-upload {
      border-color: $red;
    }
  }
  & & {
    margin-bottom: 8px;
  }
}
