.web-nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-size: 20px;
  font-weight: bold;
  padding: 15px 0;
  min-height: 140px;
  i {
    display: block;
    width: 80px;
    height: 80px;
    background: $gray;
    margin-bottom: 10px;
  }
}
