.web-c-data-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 4px;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 800;
    color: $text;
  }
  p {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
  }
  &.gray {
    p {
      height: 72px;
      background: $bgBlue;
      border-radius: 8px;
      padding: 0 32px;
      display: flex;
      align-items: center;
    }
  }
  &.large {
    padding-left: 30px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: -5px;
      height: 80px;
      width: 6px;
      background: $blue;
      border-radius: 3px;
    }
    p {
      font-size: 48px;
      line-height: 56px;
    }
  }
}
