.c-conversion {
  display: flex;
  align-items: center;
  &-inner {
    font-size: 13px;
    p {
      color: $text2;
      line-height: 16px;
      .c-row-show {
        display: inline-flex;
        margin-left: 10px;
      }
    }
    p + p {
      color: $text3;
      margin-top: 3px;
    }
  }
  &-icon {
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-left: 16px;
    @include dz();
    &:hover {
      @include fd(1.1);
    }
  }
}

.mobile-mode {
  .c-conversion {
    &-inner {
      font-size: rem(14);
      p {
        line-height: rem(16);
        .c-row-show {
          margin-left: rem(10);
        }
      }
      p + p {
        margin-top: rem(3);
      }
    }
    &-icon {
      width: rem(24);
      height: rem(24);
      margin-left: rem(16);
    }
  }
}
