.c-spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  &-default {
    width: 40px;
    height: 40px;
  }
  &-default &-dot {
    width: 5px;
    height: 5px;
    margin: 0 3.5px;
  }
  &-mini {
    width: 24px;
    height: 24px;
  }
  &-mini &-dot {
    width: 3px;
    height: 3px;
    margin: 0 2.5px;
  }
  &-small {
    width: 32px;
    height: 32px;
  }
  &-small &-dot {
    width: 4px;
    height: 4px;
    margin: 0 3px;
  }
  &-large {
    width: 60px;
    height: 60px;
  }
  &-large &-dot {
    width: 6px;
    height: 6px;
    margin: 0 4px;
  }
  &-super {
    width: 76px;
    height: 76px;
  }
  &-super &-dot {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
  &.full {
    width: 100%;
    height: 100%;
  }

  &-dot {
    border-radius: 50%;
    background: $text1;
    animation: loading 0.6s ease infinite alternate;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
      margin-right: 0;
    }
  }
}
