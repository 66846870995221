.c-label-item {
  & > label {
    display: block;
    font-size: 12px;
    color: $text2;
    padding: 0 0 8px 8px;
  }
}

.mobile-mode {
  .c-label-item {
    & > label {
      font-size: rem(14);
      padding: 0 0 rem(8) rem(8);
    }
  }
}
