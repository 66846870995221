.c-checkBox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  @include ns();

  &-inner {
    display: flex;
    align-items: center;
    input {
      display: none;
    }
  }
  &-icon {
    @include dz();
    width: 16px;
    height: 16px;
    display: block;
  }
  &-label {
    color: $text2;
    font-size: 12px;
    margin-left: 12px;
    display: flex;
    align-items: center;
  }

  &.reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  &.reverse &-label {
    margin-left: 0;
    margin-right: 12px;
  }

  &.full {
    display: flex;
    height: 28px;
  }
  &.disabled {
    cursor: default;
    * {
      cursor: default;
    }
  }
}
.mobile-mode {
  .c-checkBox {
    &-icon {
      width: rem(16);
      height: rem(16);
    }
    &-label {
      font-size: rem(14);
      margin-left: rem(12);
    }

    &.reverse &-label {
      margin-right: rem(12);
    }

    &.full {
      height: rem(28);
    }
  }
}
