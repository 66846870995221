input,
textarea,
select,
button {
  font-family: 'gilroy', 'PingFang SC', 'Heiti SC', 'Droidsansfallback', Helvetica, 'Droid Sans';
  // 重置苹果默认input样式
  -webkit-appearance: none;
  color: $c22;
  &[type='radio'] {
    -webkit-appearance: radio;
  }
  &[type='checkbox'] {
    -webkit-appearance: checkbox;
  }
  // 修改点击背景颜色
  -webkit-tap-highlight-color: rgba(#fff, 0);
  outline: none;
}
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.dark {
  input,
  textarea,
  select,
  button {
    color: $c88;
  }
}
