.c-slippage-input {
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    label {
      font-size: 12px;
      color: $text3;
      display: block;
      margin-right: 5px;
    }
  }
  &-inner {
    display: flex;
    align-items: center;

    .c-button {
      padding: 0 6px;
      margin-right: 8px;
      font-weight: normal;
    }

    .c-input-number-inner {
      height: 32px;
      padding: 4px 12px;
      input {
        font-weight: normal;
      }
    }
  }
}

.mobile-mode {
  .c-slippage-input {
    &-header {
      margin-bottom: rem(12);
      label {
        font-size: rem(14);
        margin-right: rem(5);
      }
    }
    &-inner {
      .c-button {
        padding: 0 rem(6);
        margin-right: rem(8);
      }

      .c-input-number-inner {
        height: rem(32);
        padding: rem(4) rem(12);
      }
    }
  }
}
