$skeleton-radius: 4px;
$skeleton-row-height: 18px;
$skeleton-row-margin-bottom: 18px;
$transition-duration-skeleton: 1.5s;
$skeleton-color-bg: $dark1;
$skeleton-color-animate-bg: rgba($dark1, 0.65);
$transition-linear-bezier: cubic-bezier(0, 0, 1, 1);
$skeleton-class-prefix: 'web-c-skeleton';

.#{$skeleton-class-prefix} {
  display: flex;

  &-content {
    flex: 1;
    overflow: hidden;

    .#{$skeleton-class-prefix}-rows {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .#{$skeleton-class-prefix}-row {
      background-color: $skeleton-color-bg;
      height: $skeleton-row-height;
      border-radius: $skeleton-radius;
    }

    .#{$skeleton-class-prefix}-row:not(:last-child) {
      margin-bottom: $skeleton-row-margin-bottom;
    }
  }

  &-animate {
    .#{$skeleton-class-prefix}-row {
      background: linear-gradient(
        90deg,
        $skeleton-color-bg 25%,
        $skeleton-color-animate-bg 37%,
        $skeleton-color-bg 63%
      );
      background-size: 400% 100%;
      animation: $transition-duration-skeleton $transition-linear-bezier infinite;

      @include animation-keyframes {
        0% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0 50%;
        }
      }
    }
  }
}
