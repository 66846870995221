.bar-modal {
  position: fixed;
  right: 0;
  bottom: 36px;
  width: 323px;
  z-index: 20;
  background: $bg;
  display: flex;
  flex-flow: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    h4 {
      font-size: 20px;
      color: $text1;
      font-weight: bold;
    }
    button {
      display: block;
      border: none;
      width: 32px;
      height: 32px;
      margin-right: -6px;
      background: url($res + 'icon/close.svg') center/contain no-repeat;
      cursor: pointer;
      @include dz();
      border-radius: 50%;
      &:hover {
        @include xz(180);
      }
    }
  }

  &-body {
    flex: 1;
    overflow-y: auto;
    padding: 0 24px 24px;
  }

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 19;
    // background: rgba($black, .5);
    background: rgba($bg, 0.5);
    @include mh(2px);
    // @include mh(4px);
  }
}
.mobile-mode {
  .bar-modal {
    right: 0;
    bottom: 0;
    width: 100%;
    top: 0;
    z-index: 20;

    &-header {
      padding: rem(20) rem(24);
      h4 {
        font-size: rem(20);
      }
      button {
        width: rem(28);
        height: rem(28);
        margin-right: rem(-6);
      }
    }

    &-body {
      padding: 0 rem(24) rem(24);
    }
  }
}
