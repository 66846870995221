.web-c-blank {
  display: block;
  clear: both;
  height: 40px;
  &-mini {
    height: 12px;
  }
  &-medium {
    height: 24px;
  }
  &-default {
    height: 48px;
  }
  &-large {
    height: 64px;
  }
}
