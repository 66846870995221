.c-wallet {
  &-contact-modal {
    max-height: 160px;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 18px;
    border-radius: 8px;
    overflow-y: auto;
    background-color: #000;
    padding: 8px 0 0 8px;

    button {
      font-weight: normal;
      margin-right: 8px;
      margin-bottom: 8px;
      color: $text2;
      background-color: $dark2;
      height: 36px;
      font-size: 14px;
      padding: 0 8px;

      img {
        height: 20px;
        width: 20px;
        border-radius: 4px;
      }
    }
  }

  &-contact-less-modal {
    &-btns {
      padding-top: 16px;

      button {
        font-weight: normal;
        color: $text2;
        justify-content: flex-start;
        margin-bottom: 16px;

        img {
          margin-right: 8px;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

.mobile-mode {
  .c-wallet {
    &-contact-modal {
      max-height: rem(160);
      margin-bottom: rem(18);
      border-radius: rem(8);
      padding: rem(8) 0 0 rem(8);

      button {
        height: rem(36);
        margin-right: rem(8);
        margin-bottom: rem(8);
        font-size: rem(14);
        padding: 0 rem(8);

        img {
          height: rem(20);
          border-radius: rem(4);
          width: rem(20);
        }
      }
    }

    &-contact-less-modal {
      &-btns {
        padding-top: rem(16);

        button {
          margin-bottom: rem(16);

          img {
            margin-right: rem(8);
            width: rem(24);
            height: rem(24);
          }
        }
      }
    }
  }
}
