.c-order-show-all-button {
  @include ns();
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    font-size: 13px;
    color: $text3;
  }

  button {
    width: 24px;
    height: 6px;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    &:before,
    &:after {
      content: '';
      display: block;
      height: 6px;
      border-radius: 3px;
      @include dz();
    }
    &:after {
      width: 24px;
      background: $dark1;
    }
    &:before {
      width: 0;
      background: $blue;
    }
  }
  em {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $text1;
    box-shadow: 0px 0px 5px rgba($black, 0.25);
    position: absolute;
    left: 0;
    top: -3px;
    @include dz();
  }

  &.active button {
    &:after {
      width: 0;
    }
    &:before {
      width: 24px;
    }
    em {
      left: 12px;
    }
  }
}
