.c-search-input {
  &-inner {
    position: relative;
    height: 32px;
    border-radius: 16px;
    padding: 0 32px;
    background: $dark2;
    font-size: 14px;
    display: block;

    input {
      display: block;
      width: 100%;
      border: none;
      background: none;
      height: 32px;
      padding: 8px 0;
      color: $text2;
      font-size: 14px;
      &::placeholder {
        color: $text3;
      }
    }

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 12px;
      top: 8px;
      background: url($res + 'icon/search.svg') center/contain no-repeat;
    }
  }

  &-clear {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: -1;
    display: block;
    border: none;
    width: 32px;
    height: 32px;
    background: url($res + 'icon/close.svg');
    cursor: pointer;
    @include dz();
    border-radius: 50%;
    opacity: 0;
    @include xz(-180);

    &.show {
      z-index: 2;
      opacity: 1;
      @include xz(0);
      &:hover {
        @include xz(180);
      }
    }
  }
}

.mobile-mode {
  .c-search-input {
    &-inner {
      height: rem(32);
      border-radius: rem(16);
      padding: 0 rem(32);
      font-size: rem(14);

      input {
        height: rem(32);
        padding: rem(8) 0;
        font-size: rem(14);
      }

      &:before {
        width: rem(16);
        height: rem(16);
        left: rem(12);
        top: rem(8);
      }
    }

    &-clear {
      right: rem(3);
      width: rem(32);
      height: rem(32);
    }
  }
}
